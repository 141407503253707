ptz-checkbox {
  display: flex;
  position: relative;

  .ptz-checkbox {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    background: transparent;
    border: $petz-border-md solid $petz-color-neutral-dark;
    border-radius: calc($petz-rounded-xs/2);
    position: relative;
    cursor: pointer;
  
    &-sm {
      height: calc($petz-spacing-md - 0.25rem);
      width: calc($petz-spacing-md - 0.25rem);
    }
  
    &-md {
      height: $petz-spacing-md;
      width: $petz-spacing-md;
    }
  
    &-lg {
      height: $petz-spacing-lg;
      width: $petz-spacing-lg;
    }
  
    &-icon {
      i {
        display: flex;
        position: absolute;
      }
  
      &-sm {
        i {
          left: 1px;
          top: 1px;
        }
      }
  
      &-md {
        i {
          left: 2px;
          top: 2px;
        }
      }
  
      &-lg {
        i {
          left: 4px;
          top: 4px;
        }
      }
    }
  
    &-light,
    &-dark,
    &-color {
      &-error {
        border-color: $petz-color-sup-red-mid;
        cursor: not-allowed;
      }
  
      &-disabled {
        cursor: not-allowed;
      }
    }
  
    &-light {
      border-color: $petz-color-neutral-black;
      opacity: $petz-opacity-lg;
  
      &-disabled {
        border-color: $petz-color-primary-brand;
        opacity: $petz-opacity-xs;
      }
  
      &:checked,
      &:not([disabled]) &:active {
        opacity: 1;
        border-color: $petz-color-primary-brand;
        background-color: $petz-color-primary-brand;
      }
    }
  
    &-dark,
    &-color {
      border-color: $petz-color-neutral-white;
      opacity: $petz-opacity-lg;
  
      &-disabled {
        border-color: $petz-color-neutral-white;
        opacity: $petz-opacity-xs;
      }
  
      &:checked,
      &:not([disabled]) &:active {
        opacity: 1;
        border-color: $petz-color-neutral-white;
        background-color: $petz-color-neutral-white;
      }
    }
  
    &:not([disabled]) {
  
      &:hover,
      .ptz-checkbox-icon i:hover {
        cursor: pointer;
  
        &:before {
          content: ' ';
          width: 180%;
          height: 180%;
          background-color: rgba($petz-color-neutral-dark, $petz-opacity-xs);
          border-radius: $petz-rounded-sm;
          box-sizing: border-box;
          display: block;
          margin: 0;
          position: absolute;
          left: -40%;
          top: -40%;
        }
      }
    }
  }
  
  .ptz-checkbox-selected {
    &-light {
      i {
        color: $petz-color-neutral-white;
      }
    }
  
    &-dark {
      i {
        color: $petz-color-neutral-black;
      }
    }
  
    &-color {
      i {
        color: $petz-color-primary-brand;
      }
    }
  
    &-error {
      i {
        color: $petz-color-sup-red-mid;
      }
    }
  }
}

