import { Component, Event, EventEmitter, Host, Listen, Prop, h } from '@stencil/core';
import { valueToLowerCaseForDataTestId } from '../../utils/utils';
import { PTZCheckboxConfig } from './types/ptz-checkbox.enums';
import { PTZCheckboxTypes } from './types/ptz-checkbox.types';

@Component({
  tag: 'ptz-checkbox',
  styleUrl: 'ptz-checkbox.scss',
  shadow: false,
})
export class PTZCheckbox {
  /** Cor onde o botão é aplicado */
  @Prop() appearance?: PTZCheckboxTypes.Appearance = PTZCheckboxConfig.Appearance.Light;

  /** Atributo checked padrão do Checkbox  */
  @Prop({ mutable: true }) checked: boolean = false;

  /** Estado indeterminado do checkbox */
  @Prop() indeterminate?: boolean = false;

  /** Preenche id padrão do Checkbox */
  @Prop() inputId?: string;

  /** Atributo name padrão do Checkbox */
  @Prop() name: string;

  /** Tamanho do checkbox */
  @Prop() size?: PTZCheckboxTypes.Size = PTZCheckboxConfig.Size.Medium;

  /** Estado do botão */
  @Prop() state?: PTZCheckboxTypes.State = PTZCheckboxConfig.State.Default;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  /** Atributo value padrão do Checkbox */
  @Prop() value: string;

  /** Tabindex do HTML */
  @Prop() checkboxTabindex?: number;

  /** Evento para saber se o Checkbox interno está checked e qual está checked */
  @Event({
    eventName: 'changeCheckbox',
    composed: true,
    cancelable: true,
    bubbles: true,
  })
  changeCheckbox: EventEmitter<PTZCheckboxTypes.ChangedEvent>;

  private _syncCheckedWith(value: boolean) {
    this.checked = value;
  }

  private checkboxInput: HTMLInputElement;

  @Listen('change')
  _emitCheckedEvent(event) {
    if (this.state === PTZCheckboxConfig.State.Disabled) {
      return;
    }
    this._syncCheckedWith(event.target.checked);
    const value = this.checked;
    this.changeCheckbox.emit({ checked: value, value: this.value, name: this.name });
  }

  render() {
    const classCheckbox = `
      ptz-checkbox
      ptz-checkbox-${this.size}
      ptz-checkbox-${this.appearance}${this.state && this.state !== PTZCheckboxConfig.State.Default ? `-${this.state}` : ''}
    `;

    const classIconCheckbox = {
      'ptz-checkbox-icon': true,
      [`ptz-checkbox-icon-${this.size}`]: true,
      'ptz-checkbox-selected-error': this.checked && this.state === PTZCheckboxConfig.State.Error,
      [`ptz-checkbox-selected-${this.appearance}`]: this.checked && this.state !== PTZCheckboxConfig.State.Error,
    };

    return (
      <Host>
        {this.skeleton ? (
          <ptz-skeleton width={28} height={28} rounded="xs"></ptz-skeleton>
        ) : (
          <input
            data-testid={`${this.inputId ? valueToLowerCaseForDataTestId(this.inputId) : valueToLowerCaseForDataTestId(this.value)}-checkbox`}
            type="checkbox"
            role="checkbox"
            ref={el => (this.checkboxInput = el)}
            aria-checked={this.checked}
            class={classCheckbox}
            name={this.name}
            disabled={this.state === PTZCheckboxConfig.State.Disabled}
            checked={this.checked}
            id={this.inputId}
            tabIndex={this.checkboxTabindex}
            value={this.value}
          />
        )}

        {this.checked ? (
          <ptz-icon
            onClick={() => {
              this.checked = !this.checked;
              this.checkboxInput.click();
            }}
            class={classIconCheckbox}
            name={this.indeterminate ? 'minus' : 'check'}
            size={this.size}
            variant="line"
          ></ptz-icon>
        ) : (
          ''
        )}
      </Host>
    );
  }
}
